import { Component } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert"; // Import

import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CardText from "components/Card/CardText";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";
import Delete from "@material-ui/icons/Delete";
import PersonAdd from "@material-ui/icons/PersonAdd";

import { IoIosPeople } from "react-icons/io";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
	fetchGetEmployees,
	fetchRegisterEmployee,
	fetchDeleteEmployee,
	setEmployeeGender,
	setEmployeeEmail,
	setEmployeeFirstname,
	setEmployeeLastname,
	clearEmployeeEmail,
	addEmployeeRole,
	clearEmployeeValidate,
	validateEmployeeInputs,
	resetEmployeeData
} from "actions/employee";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import { beigeColor } from "assets/jss/material-dashboard-pro-react";
import Components from "./EmployeesPage";

import {
	ROLE_EMPLOYEE,
	ROLE_WEBMASTER,
	ROLE_ADMINISTRATOR,
	TYPE_NEIGHBORHOOD,
	ROLE_NEIGHBOR
} from "actions/types.js";

const style = {
	...customInputStyle,
	...customCheckboxRadioSwitch,
	...extendedTablesStyle,
	...commonStyles,
	customUI: {
		backgroundColor: beigeColor[1],
		borderRadius: "10px",
		padding: 10
	},
	inputAdornment: {
		position: "relative"
	}
};

class AddEmployee extends Component {
	render() {
		const {
			classes,
			isFetching,
			gender,
			firstname,
			firstnameErrorMsg,
			lastname,
			lastnameErrorMsg,
			email,
			emailState,
			emailErrorMsg,
			onClose,
			onClickAdd,
			setEmployeeGender,
			setEmployeeEmail,
			setEmployeeFirstname,
			setEmployeeLastname,
			clearEmployeeEmail,
			community
		} = this.props;

		return (
			<GridItem xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary" text>
						<CardText color="primary">
							<h4 className={classes.cardTitle}>
								{community.communitytype === TYPE_NEIGHBORHOOD
									? "Ajout d'un voisin"
									: "Ajout d'un membre du personnel"}
							</h4>
						</CardText>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={12} sm={4}>
								<div className={classes.checkboxAndRadio}>
									<FormControlLabel
										control={
											<Radio
												checked={!gender || gender === "M"}
												onChange={e => setEmployeeGender(e.target.value)}
												value="M"
												name="mister"
												disabled={isFetching}
												classes={{
													checked: classes.radio,
													root: classes.radioRoot
												}}
											/>
										}
										classes={{
											label: classes.label,
											root: classes.labelRoot
										}}
										label="M."
									/>
									<FormControlLabel
										control={
											<Radio
												checked={gender && gender === "F"}
												onChange={e => setEmployeeGender(e.target.value)}
												value="F"
												name="miss"
												disabled={isFetching}
											/>
										}
										classes={{
											label: classes.label,
											root: classes.labelRoot
										}}
										label="Mme"
									/>
								</div>
							</GridItem>
							<GridItem xs={12} sm={4}>
								<CustomInput
									labelText="Prénom *"
									id="firstname"
									formControlProps={{
										fullWidth: true
									}}
									error={firstnameErrorMsg !== null}
									helperText={firstnameErrorMsg}
									inputProps={{
										value: firstname ? firstname : "",
										onChange: e => setEmployeeFirstname(e.target.value),
										disabled: isFetching
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={4}>
								<CustomInput
									labelText="Nom *"
									id="lastname"
									formControlProps={{
										fullWidth: true
									}}
									error={lastnameErrorMsg !== null}
									helperText={lastnameErrorMsg}
									inputProps={{
										value: lastname ? lastname : "",
										onChange: e => setEmployeeLastname(e.target.value),
										disabled: isFetching
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={12}>
								<CustomInput
									success={emailState === "success"}
									error={emailState === "error" || emailErrorMsg !== null}
									helperText={emailErrorMsg}
									labelText="Email *"
									id="email"
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										value: email ? email : "",
										onChange: e => setEmployeeEmail(e.target.value),
										type: "text",
										endAdornment:
											emailState === "error" ? (
												<InputAdornment position="end">
													<Close
														className={classes.danger}
														onClick={() => clearEmployeeEmail()}
													/>
												</InputAdornment>
											) : undefined
									}}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
					<CardFooter product>
						<div className={classes.w100}>
							<Button
								className={classes.floatRight}
								color="primary"
								onClick={onClickAdd}
							>
								<PersonAdd />
								{" Ajouter"}
							</Button>
							<Button className={classes.floatRight} onClick={onClose}>
								Annuler
							</Button>
						</div>
					</CardFooter>
				</Card>
			</GridItem>
		);
	}
}

class EmployeesPage extends Component {
	state = {
		br: false,
		color: "info",
		message: null,
		component: null
	};

	componentDidMount() {
		const { fetchGetEmployees, role, community } = this.props;
		fetchGetEmployees(community._id);
		if (role) {
			this.addComponent(role);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			fetchGetEmployees,
			fetchRegisterEmployee,
			gender,
			firstname,
			lastname,
			email,
			snackErrorMsg,
			snackInfoMsg,
			clearValidate,
			isValid,
			role,
			community,
			validateEmployeeInputs
		} = this.props;

		if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
			this.autoCloseMessage();
		}

		if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
			this.setState({
				component: null
			});
			fetchGetEmployees(community._id);
			this.autoCloseMessage();
		}

		if (clearValidate && !prevProps.clearValidate) {
			validateEmployeeInputs();
		}

		if (role && !prevProps.role) {
			this.addComponent(role);
		}

		if (isValid && !prevProps.isValid) {
			fetchRegisterEmployee({
				gender,
				firstname,
				lastname,
				email,
				role,
				communityId: community._id
			});
		}
	}

	hideAlert = () => {
		this.setState({
			br: false
		});
	};

	autoCloseMessage = () => {
		const { snackErrorMsg, snackInfoMsg } = this.props;

		this.setState({
			br: true,
			message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
			color: snackInfoMsg ? "info" : "danger"
		});

		if (snackInfoMsg) {
			setTimeout(this.hideAlert, 5000);
		}
	};

	onClose = () => {
		this.setState({
			component: null
		});
		this.props.resetEmployeeData();
	};

	fetchRegisterEmployee = e => {
		e.preventDefault();
		this.props.clearEmployeeValidate();
	};

	addComponent = () => {
		this.setState({
			component: (
				<Components.AddEmployee
					onClickAdd={this.fetchRegisterEmployee}
					onClose={this.onClose}
				/>
			)
		});
	};

	deleteEmployee = id => {
		this.props.fetchDeleteEmployee(id, this.props.community._id);
	};

	getRoundButtons = (id, lastname) => {
		const { classes } = this.props;
		return [{ color: "danger", icon: Delete, onClick: this.delete }].map(
			(prop, key) => {
				return (
					// !lastname && (
					<Button
						round
						color={prop.color}
						className={classes.actionButton + " " + classes.actionButtonRound}
						disabled={this.state && this.state.component ? true : false}
						key={key}
						onClick={() => prop.onClick(id)}
					>
						<prop.icon className={classes.icon} />
					</Button>
					// )
				);
			}
		);
	};

	getEmployees = () => {
		const { employees } = this.props;

		let employeesObj = [];

		if (employees) {
			employeesObj = employees.map(
				({ _id, email, firstname, lastname, description, role }) => {
					return {
						email,
						firstname,
						lastname,
						description,
						role,
						actions: (
							<div className="actions-right">
								{this.getRoundButtons(_id, lastname)}
							</div>
						)
					};
				}
			);
		}

		return employeesObj;
	};

	delete = id => {
		const { classes } = this.props;
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className={classes.customUI}>
						<h2>Confirmation</h2>
						<p>{"Êtes-vous sûr de vouloir supprimer l'employé ?"}</p>
						<div className={classes.justifyContentSpaceAround}>
							<Button
								onClick={() => {
									onClose();
								}}
							>
								Annuler
							</Button>
							<Button
								color="danger"
								onClick={() => {
									this.deleteEmployee(id);
									onClose();
								}}
							>
								Supprimer
							</Button>
						</div>
					</div>
				);
			}
		});
	};

	render() {
		const { color, message } = this.state;
		const { classes, isFetchingList, user, community, addEmployeeRole } =
			this.props;

		const columns = [
			{
				Header: "Email",
				accessor: "email",
				sortable: true,
				filterable: false
			},
			{
				Header: "Prénom",
				accessor: "firstname",
				sortable: true,
				filterable: false
			},
			{
				Header: "Nom",
				accessor: "lastname",
				sortable: true,
				filterable: false
			},
			{
				Header: "Description",
				accessor: "description",
				sortable: false,
				filterable: false
			},
			{
				Header: "Actions",
				accessor: "actions",
				sortable: false,
				filterable: false
			}
		];

		if (user.role === ROLE_WEBMASTER) {
			columns.unshift({
				Header: "Rôle",
				accessor: "role",
				sortable: true,
				filterable: false
			});
		}

		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12}>
					<Card>
						<CardHeader icon>
							<CardIcon color="primary">
								<IoIosPeople />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>
								{community.communitytype === TYPE_NEIGHBORHOOD
									? "Voisins"
									: "Membres du personnel"}
							</h4>
						</CardHeader>
						<CardBody>
							<ReactTable
								data={this.getEmployees()}
								filterable
								columns={columns}
								loading={isFetchingList}
								defaultPageSize={5}
								showPaginationTop
								showPaginationBottom={false}
								previousText="Précédent"
								nextText="Suivant"
								rowsText="Lignes"
								noDataText="Aucuns membres du personnel"
								ofText="sur"
								loadingText="Chargement..."
								className="-striped -highlight"
							/>
						</CardBody>
						<CardFooter product>
							<div className={classes.w100}>
								{user.role === ROLE_WEBMASTER && (
									<Button
										color={"blue"}
										className={classes.floatRight}
										onClick={() => addEmployeeRole(ROLE_ADMINISTRATOR)}
										disabled={this.state.component}
									>
										<PersonAdd /> {" Ajout administrateur"}
									</Button>
								)}
								{community.communitytype === TYPE_NEIGHBORHOOD && (
									<Button
										color={"primary"}
										className={classes.floatRight}
										onClick={() => addEmployeeRole(ROLE_NEIGHBOR)}
										disabled={this.state.component}
									>
										<PersonAdd />
										{" Ajout d'un voisin"}
									</Button>
								)}
								<Button
									color={"primary"}
									className={classes.floatRight}
									onClick={() => addEmployeeRole(ROLE_EMPLOYEE)}
									disabled={this.state.component}
								>
									<PersonAdd />
									{" Ajout d'un membre du personnel"}
								</Button>
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				{this.state.component}
				<Snackbar
					place="br"
					color={color}
					icon={AddAlert}
					message={message ? message : ""}
					open={this.state.br}
					closeNotification={() => this.setState({ br: false })}
					close
				/>
			</GridContainer>
		);
	}
}

EmployeesPage.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		isFetchingList,
		isFetching,
		employees,
		gender,
		firstname,
		lastname,
		email,
		role,
		snackInfoMsg,
		snackErrorMsg,
		clearValidate,
		isValid,
		firstnameErrorMsg,
		lastnameErrorMsg,
		emailState,
		emailErrorMsg
	} = state.employeeReducer;
	const { community } = state.entitiesReducer;

	return {
		isFetchingList,
		isFetching,
		employees,
		gender,
		firstname,
		lastname,
		email,
		role,
		snackInfoMsg,
		snackErrorMsg,
		clearValidate,
		isValid,
		user: state.profileReducer.user,
		firstnameErrorMsg,
		lastnameErrorMsg,
		emailState,
		emailErrorMsg,
		community
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetEmployees,
			fetchRegisterEmployee,
			fetchDeleteEmployee,
			setEmployeeGender,
			setEmployeeEmail,
			setEmployeeFirstname,
			setEmployeeLastname,
			clearEmployeeEmail,
			addEmployeeRole,
			clearEmployeeValidate,
			validateEmployeeInputs,
			resetEmployeeData
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default {
	EmployeesPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(EmployeesPage)),
	AddEmployee: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(AddEmployee))
};
