import {
	FETCH_ADD_ORGANIZATION,
	SET_FETCH_ADD_ORGANIZATION_RESULT,
	SET_FETCH_ADD_ORGANIZATION_FAILURE,
	FETCH_UPDATE_ORGANIZATION,
	SET_FETCH_UPDATE_ORGANIZATION_RESULT,
	SET_FETCH_UPDATE_ORGANIZATION_FAILURE,
	SET_ORGANIZATION_DETAILS_RESULT,
	SET_ORGANIZATION_DETAILS_FAILURE,
	FETCH_GET_ORGANIZATION_DETAILS,
	FETCH_GET_CURRENT_ORGANIZATION_DETAILS,
	SET_CURRENT_ORGANIZATION_DETAILS_RESULT,
	SET_CURRENT_ORGANIZATION_DETAILS_FAILURE,
	FETCH_DELETE_ORGANIZATION,
	SET_FETCH_DELETE_ORGANIZATION_RESULT,
	SET_FETCH_DELETE_ORGANIZATION_FAILURE,
	FETCH_GET_ORGANIZATIONS_NAMES,
	SET_ORGANIZATIONS_NAMES
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

const channel = new schema.Entity(
	"channels",
	{},
	{
		idAttribute: "_id"
	}
);

const community = new schema.Entity(
	"communities",
	{},
	{
		idAttribute: "_id"
	}
);

export const organization = new schema.Entity(
	"organization",
	{
		channelsIds: [channel],
		communitiesIds: [community]
	},
	{
		idAttribute: "_id"
	}
);

const organizations = new schema.Entity(
	"organizationsNames",
	{},
	{
		idAttribute: "_id"
	}
);

const infospage = new schema.Entity("infospages", {
	docs: [organizations]
});

export function fetchGetOrganizationsNames(
	page = 1,
	pageSize = 5,
	sorted = [],
	name
) {
	return apiAction({
		url: "/communities",
		method: "GET",
		schema: infospage,
		data: {
			communitytype: "Organization",
			page,
			pageSize,
			sorted:
				sorted.length > 0
					? sorted.map(elt => elt.id + (elt.desc ? ":-1" : ":1")).join("|")
					: undefined,
			name
		},
		onSuccess: setOrganizationsNames,
		label: FETCH_GET_ORGANIZATIONS_NAMES
	});
}

function setOrganizationsNames(normalized) {
	return {
		type: SET_ORGANIZATIONS_NAMES,
		entities: normalized.entities
	};
}

export function fetchGetCurrentOrganizationDetails() {
	return apiAction({
		url: "/organizations/organization",
		method: "GET",
		schema: organization,
		onSuccess: setCurrentOrganizationDetailsResult,
		onFailure: setCurrentOrganizationDetailsFailure,
		label: FETCH_GET_CURRENT_ORGANIZATION_DETAILS
	});
}

function setCurrentOrganizationDetailsResult(normalized) {
	return {
		type: SET_CURRENT_ORGANIZATION_DETAILS_RESULT,
		entities: normalized.entities
	};
}

function setCurrentOrganizationDetailsFailure(error) {
	return {
		type: SET_CURRENT_ORGANIZATION_DETAILS_FAILURE,
		error
	};
}

export function fetchGetOrganizationDetails(id) {
	return apiAction({
		url: "/organizations/organization/" + id,
		method: "GET",
		schema: organization,
		onSuccess: setOrganizationDetailsResult,
		onFailure: setOrganizationDetailsFailure,
		label: FETCH_GET_ORGANIZATION_DETAILS
	});
}

function setOrganizationDetailsResult(normalized) {
	return {
		type: SET_ORGANIZATION_DETAILS_RESULT,
		entities: normalized.entities
	};
}

function setOrganizationDetailsFailure(error) {
	return {
		type: SET_ORGANIZATION_DETAILS_FAILURE,
		error
	};
}

export function fetchDeleteOrganization(id, name) {
	return apiAction({
		url: "/organizations/organization/" + id + "?name=" + name,
		method: "DELETE",
		onSuccess: setDeleteOrganizationResult,
		onFailure: setDeleteOrganizationFailure,
		label: FETCH_DELETE_ORGANIZATION
	});
}

function setDeleteOrganizationResult(data) {
	return {
		type: SET_FETCH_DELETE_ORGANIZATION_RESULT,
		payload: data
	};
}

function setDeleteOrganizationFailure(error) {
	return {
		type: SET_FETCH_DELETE_ORGANIZATION_FAILURE,
		error
	};
}

export function fetchAddOrganization(organization) {
	return apiAction({
		url: "/organizations",
		method: "POST",
		data: organization,
		onSuccess: setFetchAddOrganizationResult,
		onFailure: setFetchAddOrganizationFailure,
		label: FETCH_ADD_ORGANIZATION
	});
}

function setFetchAddOrganizationResult(data) {
	return {
		type: SET_FETCH_ADD_ORGANIZATION_RESULT,
		payload: data
	};
}

function setFetchAddOrganizationFailure(error) {
	return {
		type: SET_FETCH_ADD_ORGANIZATION_FAILURE,
		error
	};
}

export function fetchUpdateOrganization(organization) {
	return apiAction({
		url: "/organizations/organization/" + organization._id,
		method: "PUT",
		data: organization,
		onSuccess: setFetchUpdateOrganizationResult,
		onFailure: setFetchUpdateOrganizationFailure,
		label: FETCH_UPDATE_ORGANIZATION
	});
}

function setFetchUpdateOrganizationResult(data) {
	return {
		type: SET_FETCH_UPDATE_ORGANIZATION_RESULT,
		payload: data
	};
}

function setFetchUpdateOrganizationFailure(error) {
	return {
		type: SET_FETCH_UPDATE_ORGANIZATION_FAILURE,
		error
	};
}
