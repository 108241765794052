import { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// material ui icons
import Person from "@material-ui/icons/Person";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import PictureUpload from "components/CustomUpload/PictureUpload";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Snackbar from "components/Snackbar/Snackbar";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

// DigiHapi components
import Activity from "components/DigiHapi/Activity";

// Redux
import { useDispatch, useSelector } from "react-redux";

import { fetchUpdateProfile } from "actions/profile";
import {
	SET_FIRST_NAME,
	SET_LAST_NAME,
	SET_GENDER,
	SET_PHONE,
	VALIDATE_PROFILE_INPUTS,
	CLEAR_PROFILE_VALIDATE,
	SET_PROFILE_FILES_IMAGES,
	REMOVE_PROFILE_IMAGE,
	SET_DESCRIPTION_FR,
	SET_DESCRIPTION_EN,
	SET_PROFILE_EDIT
} from "actions/types.js";
import { PhoneMask } from "utils/masks";

const styles = {
	...loginPageStyle,
	...customCheckboxRadioSwitch,
	...commonStyles
};

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
	const classes = useStyles();

	const dispatch = useDispatch();

	const {
		isFetching,
		firstname,
		lastname,
		gender,
		address,
		zipCode,
		city,
		phone,
		description,
		firstnameErrorMsg,
		lastnameErrorMsg,
		phoneErrorMsg,
		isValid,
		clearValidate,
		photoUri,
		files,
		profileEdit,
		snackInfoMsg,
		snackErrorMsg,
		lang
	} = useSelector(state => state.profileReducer);

	useEffect(() => {
		if (clearValidate) {
			dispatch({
				type: VALIDATE_PROFILE_INPUTS
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clearValidate]);

	useEffect(() => {
		if (isValid) {
			dispatch(
				fetchUpdateProfile({
					photoUri,
					firstname,
					lastname,
					gender,
					address,
					zipCode,
					files,
					city,
					phone,
					description,
					lang
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValid]);

	const [br, setBr] = useState(false);
	const [message, setMessage] = useState(null);
	const [color, setColor] = useState("info");

	useEffect(() => {
		if (snackInfoMsg || snackErrorMsg) {
			setBr(true);
			setMessage(snackInfoMsg ? snackInfoMsg : snackErrorMsg);
			setColor(snackInfoMsg ? "info" : "danger");
			if (snackInfoMsg) {
				setTimeout(() => setBr(false), 5000);
			}
		}
	}, [snackInfoMsg, snackErrorMsg]);

	const updateProfile = e => {
		e.preventDefault();
		dispatch({
			type: CLEAR_PROFILE_VALIDATE
		});
	};

	const onImageLoaded = (name, imagePreviewUrl) => {
		dispatch({
			type: SET_PROFILE_FILES_IMAGES,
			payload: [{ name, base64: imagePreviewUrl }]
		});
	};

	const onImageRemoved = () => {
		dispatch({
			type: REMOVE_PROFILE_IMAGE
		});
	};

	return (
		<GridContainer justifyContent="center">
			<GridItem xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary" icon>
						<CardIcon color="primary">
							<Person />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Profil</h4>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={12} sm={3}>
								<PictureUpload
									onImageLoaded={onImageLoaded}
									onImageRemoved={onImageRemoved}
									disabled={!profileEdit || isFetching}
									photoUri={photoUri}
									gender={gender}
								/>
							</GridItem>
							<GridItem xs={12} sm={9}>
								<GridContainer>
									<GridItem xs={12} sm={4}>
										<div className={classes.checkboxAndRadio}>
											<FormControlLabel
												control={
													<Radio
														checked={!gender}
														onChange={e =>
															dispatch({
																type: SET_GENDER,
																value: null
															})
														}
														value={null}
														name="unknown"
														disabled={!profileEdit || isFetching}
														classes={{
															checked: classes.radioBrown,
															root: classes.radioRoot
														}}
													/>
												}
												classes={{
													label: classes.label,
													root: classes.labelRoot
												}}
												label="?"
											/>
											<FormControlLabel
												control={
													<Radio
														checked={gender === "M"}
														onChange={e =>
															dispatch({
																type: SET_GENDER,
																value: e.target.value
															})
														}
														value="M"
														name="mister"
														disabled={!profileEdit || isFetching}
														classes={{
															checked: classes.radio,
															root: classes.radioRoot
														}}
													/>
												}
												classes={{
													label: classes.label,
													root: classes.labelRoot
												}}
												label="M."
											/>
											<FormControlLabel
												control={
													<Radio
														checked={gender === "F"}
														onChange={e =>
															dispatch({
																type: SET_GENDER,
																value: e.target.value
															})
														}
														value="F"
														name="miss"
														disabled={!profileEdit || isFetching}
													/>
												}
												classes={{
													label: classes.label,
													root: classes.labelRoot
												}}
												label="Mme"
											/>
										</div>
									</GridItem>

									<GridItem xs={12} sm={4}>
										<CustomInput
											labelText="Prénom *"
											id="firstname"
											formControlProps={{
												fullWidth: true
											}}
											error={firstnameErrorMsg !== null}
											helperText={firstnameErrorMsg}
											inputProps={{
												value: firstname ? firstname : "",
												onChange: e =>
													dispatch({
														type: SET_FIRST_NAME,
														value: e.target.value
													}),
												disabled: !profileEdit || isFetching
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={4}>
										<CustomInput
											labelText="Nom *"
											id="lastname"
											formControlProps={{
												fullWidth: true
											}}
											error={lastnameErrorMsg !== null}
											helperText={lastnameErrorMsg}
											inputProps={{
												value: lastname ? lastname : "",
												onChange: e =>
													dispatch({
														type: SET_LAST_NAME,
														value: e.target.value
													}),
												disabled: !profileEdit || isFetching
											}}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={12} sm={3}>
								<CustomInput
									labelText="Téléphone"
									id="phone"
									formControlProps={{
										fullWidth: true
									}}
									error={phoneErrorMsg !== null}
									helperText={phoneErrorMsg}
									inputProps={{
										placeholder: "+33 (0)X XX XX XX XX",
										inputComponent: profileEdit ? PhoneMask : undefined,
										value: phone ? phone : "",
										onChange: e =>
											dispatch({
												type: SET_PHONE,
												value: e.target.value
											}),
										disabled: !profileEdit || isFetching
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={9}>
								<GridContainer>
									<GridItem xs={12} sm={6}>
										<CustomInput
											labelText="Description fr"
											id="description"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												value: description ? description : "",
												onChange: e =>
													dispatch({
														type: SET_DESCRIPTION_FR,
														value: e.target.value
													}),
												disabled: !profileEdit || isFetching
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={6}>
										<CustomInput
											labelText="Description en"
											id="description"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												value: lang && lang.en ? lang.en.description : "",
												onChange: e =>
													dispatch({
														type: SET_DESCRIPTION_EN,
														value: e.target.value
													}),
												disabled: !profileEdit || isFetching
											}}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
						<Snackbar
							place="br"
							color={color}
							icon={AddAlert}
							message={message ? message : ""}
							open={br}
							closeNotification={() => setBr(false)}
							close
						/>
					</CardBody>
					<CardFooter product>
						<div className={classes.w100}>
							<Button
								color={profileEdit ? "primary" : undefined}
								onClick={updateProfile}
								className={classes.floatRight}
								disabled={isFetching || !profileEdit}
							>
								{isFetching ? <Activity /> : "Modifier"}
							</Button>
							<Button
								color={profileEdit ? undefined : "brown"}
								className={classes.floatRight}
								onClick={() =>
									dispatch({ type: SET_PROFILE_EDIT, value: !profileEdit })
								}
							>
								{profileEdit ? "Annuler" : "Edition"}
							</Button>
						</div>
					</CardFooter>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
