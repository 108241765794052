import AboutPage from "views/Pages/AboutPage";
import Campsites from "views/Pages/Campsites/CampsitesPage";
import CommunityPage from "views/Pages/CommunityPage";
import OrganizationPage from "views/Pages/OrganizationPage";
import Employees from "views/Pages/Employees/EmployeesPage";
import LocationsOrderPage from "views/Pages/Locations/LocationsOrderPage";
import Locations from "views/Pages/Locations/LocationsPage";
import Neighborhoods from "views/Pages/Neighborhoods/NeighborhoodsPage";
import PasswordPage from "views/Pages/PasswordPage";
import CommunityPlacesPage from "views/Pages/Places/CommunityPlacesPage";
import Places from "views/Pages/Places/PlacesPage";
import ProfilePage from "views/Pages/ProfilePage";
import TemplatesOrder from "views/Pages/Templates/TemplatesOrderPage";
import Templates from "views/Pages/Templates/TemplatesPage";
import TemplatesCategories from "views/Pages/TemplatesCategories/TemplatesCategoriesPage";

// @material-ui/icons
import Apartment from "@material-ui/icons/Apartment";
import People from "@material-ui/icons/People";
import VerticalSplit from "@material-ui/icons/VerticalSplit";

import {
	FaCampground,
	FaHandHoldingHeart,
	FaRegNewspaper
} from "react-icons/fa";
import { GiBarracksTent, GiPaperTray } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";
import { RiHeartsFill } from "react-icons/ri";
import { VscDebugStepInto } from "react-icons/vsc";

const dashRoutes = ({
	organization: { organizationCommunityType } = {},
	community: { communitytype } = {}
}) => [
	{
		path: "/about",
		name: "À propos",
		component: AboutPage,
		layout: "/team",
		invisible: true
	},
	...(organizationCommunityType
		? [
				{
					path: "/organization",
					name: "Organisation",
					component: OrganizationPage,
					layout: "/team",
					invisible: true
				}
		  ]
		: []),
	...(communitytype !== "Organization"
		? [
				{
					path: "/community",
					name: "Communauté",
					component: CommunityPage,
					layout: "/team",
					invisible: true
				}
		  ]
		: []),
	{
		path: "/profile",
		name: "Profil",
		icon: People,
		component: ProfilePage,
		layout: "/team",
		invisible: true
	},
	{
		path: "/change-password",
		name: "Mot de passe",
		component: PasswordPage,
		layout: "/team",
		invisible: true
	},
	...(organizationCommunityType === "Neighborhood" &&
	communitytype === "Organization"
		? [
				{
					path: "/neighborhoods",
					name: "Voisinage",
					icon: Apartment,
					component: Neighborhoods.NeighborhoodsPage,
					layout: "/admin"
				}
		  ]
		: []),
	...(organizationCommunityType === "Campsite" &&
	communitytype === "Organization"
		? [
				{
					path: "/campings",
					name: "Campings",
					icon: FaCampground,
					component: Campsites.CampsitesPage,
					layout: "/admin"
				}
		  ]
		: []),
	{
		path: "/places",
		name: "Lieux de rdv",
		icon: VscDebugStepInto,
		component: Places.PlacesPage,
		layout: "/admin"
	},
	...(communitytype === "Campsite"
		? [
				{
					path: "/accomodations",
					name: "Hébergements",
					icon: GiBarracksTent,
					component: Locations.LocationsPage,
					layout: "/admin"
				}
		  ]
		: []),
	{
		path: "/members",
		name: "Membres",
		icon: IoIosPeople,
		component: Employees.EmployeesPage,
		layout: "/admin"
	},
	{
		collapse: true,
		name: "Modèles",
		icon: FaRegNewspaper,
		state: "templateCollapse",
		views: [
			{
				path: "/categories",
				name: "Catégories",
				icon: GiPaperTray,
				component: TemplatesCategories.TemplatesCategoriesPage,
				layout: "/team"
			},
			{
				path: "/templates",
				name: "Modèles",
				icon: FaRegNewspaper,
				component: Templates.TemplatesPage,
				layout: "/team"
			}
		]
	},
	{
		collapse: true,
		name: "Instants partage",
		icon: RiHeartsFill,
		state: "sharingCollapse",
		views: [
			{
				path: "/sharing-categories",
				name: "Catégories",
				icon: GiPaperTray,
				component: TemplatesCategories.ActivitiesCategoriesPage,
				layout: "/admin"
			},
			{
				path: "/instant-sharing",
				name: "Instants partage",
				icon: RiHeartsFill,
				component: Templates.ActivitiesPage,
				layout: "/admin"
			}
		]
	},
	{
		collapse: true,
		name: "Besoins",
		icon: FaHandHoldingHeart,
		state: "proposalsCollapse",
		views: [
			{
				path: "/need-categories",
				name: "Catégories",
				icon: GiPaperTray,
				component: TemplatesCategories.ProposalsCategoriesPage,
				layout: "/admin"
			},
			{
				path: "/needs",
				name: "Besoins",
				icon: FaHandHoldingHeart,
				component: Templates.ProposalsPage,
				layout: "/admin"
			}
		]
	},
	{
		collapse: true,
		name: "Réorganiser",
		icon: VerticalSplit,
		state: "reorderCollapse",
		views: [
			{
				path: "/organize-places",
				name: "Lieux de rdv",
				icon: VscDebugStepInto,
				component: CommunityPlacesPage,
				layout: "/admin"
			},
			...(communitytype === "Campsite"
				? [
						{
							path: "/organize-accommodations",
							name: "Hébergements",
							icon: GiBarracksTent,
							component: LocationsOrderPage,
							layout: "/admin"
						}
				  ]
				: []),
			{
				path: "/organize-templates",
				name: "Modèles",
				icon: FaRegNewspaper,
				component: TemplatesOrder.TemplatesOrderPage,
				layout: "/admin"
			},
			{
				path: "/organize-sharing",
				name: "Instants partage",
				icon: RiHeartsFill,
				component: TemplatesOrder.ActivitiesOrderPage,
				layout: "/admin"
			},
			{
				path: "/organize-needs",
				name: "Besoins",
				icon: FaHandHoldingHeart,
				component: TemplatesOrder.ProposalsOrderPage,
				layout: "/admin"
			}
		]
	}
];

export default dashRoutes;
