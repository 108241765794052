import AboutPage from "views/Pages/AboutPage";
import Campsites from "views/Pages/Campsites/CampsitesPage";
import CommunityPage from "views/Pages/CommunityPage";
import Neighborhoods from "views/Pages/Neighborhoods/NeighborhoodsPage";
import OrganizationPage from "views/Pages/OrganizationPage";
import PasswordPage from "views/Pages/PasswordPage";
import ProfilePage from "views/Pages/ProfilePage";
import Templates from "views/Pages/Templates/TemplatesPage";
import TemplatesCategories from "views/Pages/TemplatesCategories/TemplatesCategoriesPage";

// @material-ui/icons
import Apartment from "@material-ui/icons/Apartment";

import { FaCampground, FaRegNewspaper } from "react-icons/fa";
import { GiPaperTray } from "react-icons/gi";

const dashRoutes = ({
	organization: { organizationCommunityType } = {},
	community: { communitytype } = {}
}) => [
	{
		path: "/about",
		name: "À propos",
		component: AboutPage,
		layout: "/team",
		invisible: true
	},
	...(organizationCommunityType
		? [
				{
					path: "/organization",
					name: "Organisation",
					component: OrganizationPage,
					layout: "/team",
					invisible: true
				}
		  ]
		: []),
	...(communitytype !== "Organization"
		? [
				{
					path: "/community",
					name: "Communauté",
					component: CommunityPage,
					layout: "/team",
					invisible: true
				}
		  ]
		: []),
	{
		path: "/profile",
		name: "Profil",
		component: ProfilePage,
		layout: "/team",
		invisible: true
	},
	{
		path: "/change-password",
		name: "Mot de passe",
		component: PasswordPage,
		layout: "/team",
		invisible: true
	},
	...(organizationCommunityType === "Neighborhood" &&
	communitytype === "Organization"
		? [
				{
					path: "/neighborhoods",
					name: "Voisinage",
					icon: Apartment,
					component: Neighborhoods.NeighborhoodsPage,
					layout: "/team"
				}
		  ]
		: []),
	...(organizationCommunityType === "Campsite" &&
	communitytype === "Organization"
		? [
				{
					path: "/campings",
					name: "Campings",
					icon: FaCampground,
					component: Campsites.CampsitesPage,
					layout: "/team"
				}
		  ]
		: []),
	{
		path: "/categories",
		name: "Catégories",
		icon: GiPaperTray,
		component: TemplatesCategories.TemplatesCategoriesPage,
		layout: "/team"
	},
	{
		path: "/templates",
		name: "Modèles",
		icon: FaRegNewspaper,
		component: Templates.TemplatesPage,
		layout: "/team"
	}
];

export default dashRoutes;
