import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import loginReducer from "./login";
import profileReducer from "./profile";
import entitiesReducer from "./entities";
import uploadReducer from "./upload";
import eventReducer from "./event";
import templateReducer from "./template";
import templateCategoryReducer from "./templateCategory";
import employeeReducer from "./employee";
import communityReducer from "./community";
import campsiteReducer from "./campsite";
import cityReducer from "./city";
import neighborhoodReducer from "./neighborhood";
import gooddealReducer from "./gooddeal";
import versionReducer from "./version";
import locationReducer from "./location";
import equipmentReducer from "./equipment";
import auditReducer from "./audit";
import navReducer from "./nav";
import organizationReducer from "./organization";
import errorReducer from "./error";

export default function createReducers(history) {
	const appReducer = combineReducers({
		router: connectRouter(history),
		profileReducer,
		entitiesReducer,
		loginReducer,
		uploadReducer,
		eventReducer,
		templateReducer,
		templateCategoryReducer,
		employeeReducer,
		communityReducer,
		campsiteReducer,
		cityReducer,
		neighborhoodReducer,
		gooddealReducer,
		versionReducer,
		locationReducer,
		equipmentReducer,
		auditReducer,
		navReducer,
		organizationReducer,
		errorReducer
	});
	return appReducer;
}
