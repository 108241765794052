import { Component, createRef, Fragment } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert"; // Import

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import Wizard from "components/DigiHapi/Wizard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import FileCopy from "@material-ui/icons/FileCopy";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Place from "@material-ui/icons/Place";

import { FaRegNewspaper, FaHandHoldingHeart } from "react-icons/fa";
import { RiHeartsFill } from "react-icons/ri";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {
	beigeColor,
	blueColor,
	successColor
} from "assets/jss/material-dashboard-pro-react";
import { cla, flex, flex1, column } from "assets/jss/ocamping/commonStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import LinesEllipsis from "react-lines-ellipsis";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
	fetchGetTemplates,
	fetchAddTemplate,
	fetchGetTemplateDetails,
	fetchUpdateTemplate,
	fetchDeleteTemplate,
	fetchCloneTemplate
} from "actions/template";
import { fetchGetEquipments } from "actions/equipment";
import { fetchGetPictosUri } from "actions/index";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step2_Activities from "./Step2_Activities";
import Step3 from "./Step3";
import Step3_Activities from "./Step3_Activities";
import Step4 from "./Step4";
import "react-pagination-js/dist/styles.css"; // import css

import {
	RESET_TEMPLATE,
	SET_TEMPLATE_CATEGORY,
	SET_TEMPLATE_NEW_CATEGORY,
	SET_TEMPLATE_TYPE,
	ROLE_WEBMASTER,
	SET_TEMPLATE_SYSTEM
} from "actions/types.js";

import Components from "./TemplatesPage";
import Activity from "components/DigiHapi/Activity";

const style = {
	...dashboardStyle,
	...extendedTablesStyle,
	...commonStyles,
	customUI: {
		backgroundColor: beigeColor[1],
		borderRadius: "10px",
		padding: 10
	},
	blue: {
		color: blueColor[0]
	},
	success: {
		color: successColor[0]
	},
	descriptionContainer: {
		height: "60px"
	},
	displayNone: {
		display: "none"
	},
	preWrap: {
		whiteSpace: "pre-wrap"
	}
};

class TemplateWizard extends Component {
	fetchAddTemplate = () => {
		const {
			fetchAddTemplate,
			categoryId,
			name,
			files,
			picto,
			description,
			postDescription,
			gooddealPostDescription,
			gooddealDescription,
			url,
			lang,
			dateId,
			timeId,
			equipmentId,
			acceptNotif,
			type,
			system,
			choices,
			community,
			usedByGooddeals
		} = this.props;

		fetchAddTemplate({
			categoryId,
			name,
			files,
			picto,
			description,
			postDescription,
			gooddealPostDescription,
			gooddealDescription,
			url,
			lang,
			dateId,
			timeId,
			equipmentId,
			acceptNotif,
			type,
			system,
			choices,
			communityId: community._id,
			usedByGooddeals
		});
	};

	fetchUpdateTemplate = () => {
		const {
			fetchUpdateTemplate,
			_id,
			categoryId,
			community,
			newCategoryId,
			name,
			files,
			description,
			postDescription,
			gooddealPostDescription,
			gooddealDescription,
			url,
			lang,
			dateId,
			timeId,
			equipmentId,
			acceptNotif,
			photoUri,
			picto,
			type,
			system,
			choices,
			usedByGooddeals
		} = this.props;

		fetchUpdateTemplate({
			_id,
			categoryId,
			communityId: community._id,
			newCategoryId,
			name,
			files,
			description,
			postDescription,
			gooddealPostDescription,
			gooddealDescription,
			url,
			lang,
			dateId,
			timeId,
			equipmentId,
			acceptNotif,
			photoUri,
			picto,
			type,
			system,
			choices,
			usedByGooddeals
		});
	};

	render() {
		const { update, isFetching, onClose, type, label } = this.props;
		return (
			<GridItem xs={12} sm={12}>
				<Wizard
					ref={this._wizard}
					onClose={onClose}
					isFetching={isFetching}
					validate
					steps={[
						{
							stepName: "Illustration",
							stepComponent: Step1,
							stepId: "picture"
						},
						{
							stepName: "Français",
							stepComponent: type === "Template" ? Step2 : Step2_Activities,
							stepId: "french"
						},
						{
							stepName: "Anglais",
							stepComponent: type === "Template" ? Step3 : Step3_Activities,
							stepId: "english"
						},
						{
							stepName: "Valeurs par défaut",
							stepComponent: Step4,
							stepId: "default"
						}
					]}
					title={update ? `Mise à jour d'${label}` : `Ajout d'${label}`}
					subtitle=""
					finishButtonClick={
						update ? this.fetchUpdateTemplate : this.fetchAddTemplate
					}
				/>
			</GridItem>
		);
	}
}

class Templates extends Component {
	state = {
		br: false,
		isFetching: false,
		color: "info",
		message: null,
		wizard: null,
		data: [],
		sizePerPage: 3,
		page: 0,
		totalPages: 0,
		totalDocs: 0,
		templates: {},
		modeTable: false
	};

	constructor(props) {
		super(props);
		this.tableRef = createRef();
		this.wizardRef = createRef();
	}

	componentDidMount() {
		const {
			fetchGetPictosUri,
			fetchGetEquipments,
			fetchGetTemplates,
			type,
			community,
			pictos
		} = this.props;
		if (!pictos) {
			fetchGetPictosUri();
		}
		fetchGetEquipments(community._id);
		fetchGetTemplates(type, community._id);
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			fetchGetTemplates,
			type,
			snackErrorMsg,
			snackInfoMsg,
			newWizard,
			templates,
			community
		} = this.props;

		if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
			this.autoCloseMessage();
		}

		if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
			this.setState({
				wizard: null
			});
			fetchGetTemplates(type, community._id);
			this.autoCloseMessage();
		}

		if (
			Object.keys(templates).length > 0 &&
			Object.keys(prevProps.templates).length === 0
		) {
			this.changeCurrentPage(1);
		}

		if (newWizard && prevProps.newWizard !== newWizard) {
			this.addWizard(newWizard === "update");
		}
	}

	hideAlert = () => {
		this.setState({
			br: false
		});
	};

	autoCloseMessage = () => {
		const { snackErrorMsg, snackInfoMsg } = this.props;

		this.setState({
			br: true,
			message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
			color: snackInfoMsg ? "info" : "danger"
		});

		if (snackInfoMsg) {
			setTimeout(this.hideAlert, 5000);
		}
	};

	onClose = () => {
		this.setState({
			wizard: null
		});
		this.props.dispatch({
			type: RESET_TEMPLATE
		});
	};

	addWizard = (update = true) => {
		const { dispatch, classes, type, label } = this.props;
		dispatch({
			type: SET_TEMPLATE_TYPE,
			payload: type
		});
		this.setState(
			{
				wizard: (
					// wrap the React component in a standard DOM element and assign a ref to that
					// to scroll correctly
					<div
						className={classes.w100}
						ref={elt => {
							this.wizardRef = elt;
						}}
					>
						<Components.TemplateWizard
							update={update}
							onClose={this.onClose}
							type={type}
							label={label}
						/>
					</div>
				)
			},
			() => this.wizardRef.scrollIntoView({ behavior: "smooth" })
		);
	};

	editTemplate = ({ categoryId, _id }) => {
		const { fetchGetTemplateDetails, dispatch, community } = this.props;
		fetchGetTemplateDetails(_id, community._id);
		dispatch({
			type: SET_TEMPLATE_CATEGORY,
			categoryId
		});
		dispatch({
			type: SET_TEMPLATE_NEW_CATEGORY,
			categoryId
		});
	};

	deleteTemplate = (categoryId, id) => {
		this.props.fetchDeleteTemplate(categoryId, id);
	};

	cloneTemplate = ({ categoryId, _id }) => {
		const { fetchCloneTemplate, community } = this.props;
		fetchCloneTemplate(categoryId, _id, community._id);
	};

	getRoundButtons = template => {
		const { classes, user } = this.props;
		const { system, unused = false } = template;

		const actions = [];
		if (system) {
			if (unused) {
				actions.push({
					color: "success",
					icon: FileCopy,
					tooltip: "Dupliquer",
					onClick: this.cloneTemplate
				});
			}
			if (user.role === ROLE_WEBMASTER) {
				actions.push({
					color: "info",
					icon: Edit,
					tooltip: "Modifier",
					onClick: this.editTemplate
				});
				actions.push({
					color: "danger",
					icon: Delete,
					tooltip: "Supprimer",
					onClick: this.delete
				});
			}
		} else {
			actions.push({
				color: "brown",
				icon: Edit,
				tooltip: "Modifier",
				onClick: this.editTemplate
			});
			actions.push({
				color: "danger",
				icon: Delete,
				tooltip: "Supprimer",
				onClick: this.delete
			});
		}
		return actions.map((prop, key) => {
			return (
				<Tooltip
					key={key}
					title={prop.tooltip}
					placement="bottom"
					classes={{ tooltip: classes.tooltip }}
				>
					<Button
						round
						color={prop.color}
						className={classes.actionButton + " " + classes.actionButtonRound}
						disabled={this.state && this.state.wizard ? true : false}
						onClick={() => prop.onClick(template)}
					>
						<prop.icon className={classes.icon} />
					</Button>
				</Tooltip>
			);
		});
	};

	get2Chars = param => {
		return ("" + param).length === 1 ? "0" + param : param;
	};

	formatDate = d => {
		const date = new Date(d);
		return `${this.get2Chars(date.getDate())}/${this.get2Chars(
			date.getMonth() + 1
		)}/${date.getFullYear()} ${this.get2Chars(
			date.getHours()
		)}:${this.get2Chars(date.getMinutes())}`;
	};

	getTemplates = () => {
		const { templates } = this.props;

		const result = Object.values(templates).map(
			({ _id, dateUpdated, name, category, categoryId, system, unused }) => {
				return {
					_id,
					date: dateUpdated,
					name,
					category,
					actions: (
						<div className="actions-right">
							{this.getRoundButtons({
								_id,
								name,
								category,
								categoryId,
								system,
								unused
							})}
						</div>
					)
				};
			}
		);
		return result;
	};

	delete = template => {
		const { classes, type } = this.props;
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className={classes.customUI}>
						<h2>Confirmation</h2>
						<p>{`Êtes-vous sûr de vouloir supprimer ${
							type === "Activity" ? "l'activité" : "le modèle"
						} ?`}</p>
						<div className={classes.justifyContentSpaceAround}>
							<Button
								onClick={() => {
									onClose();
								}}
							>
								Annuler
							</Button>
							<Button
								color="danger"
								onClick={() => {
									this.deleteTemplate(template.categoryId, template._id);
									onClose();
								}}
							>
								Supprimer
							</Button>
						</div>
					</div>
				);
			}
		});
	};

	renderTemplates = () => {
		const { classes, templates, type, equipments, user, pictos } = this.props;
		const { data, page, totalDocs, sizePerPage, wizard } = this.state;
		const components = [];

		const indexBegin = page === 0 ? 0 : (page - 1) * sizePerPage;
		const indexEnd =
			page * sizePerPage > totalDocs ? totalDocs : page * sizePerPage;
		// eslint-disable-next-line
		for (let index = indexBegin; index < indexEnd; index++) {
			const obj = data[index];
			const template = templates[obj._original._id];
			if (template) {
				components.push(
					<GridItem
						key={template._id}
						xs={12}
						sm={12}
						md={4}
						className={cla(classes, [flex])}
					>
						<Card product className={classes.cardHover}>
							{type === "Template" ? (
								<CardHeader
									image
									className={wizard ? "" : classes.cardHeaderHover}
								>
									<a href="#pablo" onClick={e => e.preventDefault()}>
										<img src={template.photoUri} alt={template.name} />
									</a>
								</CardHeader>
							) : (
								<CardHeader
									icon
									className={wizard ? "" : classes.cardHeaderHover}
								>
									<CardIcon color="beige">
										<img
											src={pictos ? pictos[template.picto] : ""}
											alt={`${template.picto} made by Freepik from www.flaticon.com`}
										/>
									</CardIcon>
								</CardHeader>
							)}
							<CardBody className={cla(classes, [flex, flex1, column])}>
								<div
									className={
										type === "Template"
											? classes.cardHoverUnder
											: classes.cardHoverUnderIcon
									}
								>
									{template.system && template.unused && (
										<Tooltip
											id="tooltip-clone"
											title="Dupliquer"
											placement="bottom"
											classes={{ tooltip: classes.tooltip }}
										>
											<Button
												color="success"
												simple
												justIcon
												onClick={() => this.cloneTemplate(template)}
											>
												<FileCopy className={classes.underChartIcons} />
											</Button>
										</Tooltip>
									)}
									{(!template.system ||
										(template.system && user.role === ROLE_WEBMASTER)) && (
										<Fragment>
											<Tooltip
												id="tooltip-top"
												title="Modifier"
												placement="bottom"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													color="brown"
													simple
													justIcon
													onClick={() => this.editTemplate(template)}
												>
													<Edit className={classes.underChartIcons} />
												</Button>
											</Tooltip>
											<Tooltip
												id="tooltip-top"
												title="Supprimer"
												placement="bottom"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													color="danger"
													simple
													justIcon
													onClick={() => this.delete(template)}
												>
													<Delete className={classes.underChartIcons} />
												</Button>
											</Tooltip>
										</Fragment>
									)}
								</div>
								<h3 className={classes.cardProductTitle}>
									<a href="#pablo" onClick={e => e.preventDefault()}>
										{template.name}
									</a>
								</h3>
								<div className={classes.descriptionContainer}>
									<LinesEllipsis
										className={
											classes.cardProductDesciprion + " " + classes.preWrap
										}
										text={template.description}
										maxLine={3}
										trimRight
										basedOn="letters"
									/>
								</div>
							</CardBody>
							<CardFooter product>
								<div className={classes.blue}>
									<h4>{template.system ? "S" : ""}</h4>
								</div>
								<Tooltip
									id="tooltip-location"
									title="Lieu"
									placement="bottom"
									classes={{ tooltip: classes.tooltip }}
								>
									<div className={classes.success}>
										<Place />
										{template.equipmentId && equipments?.[template.equipmentId]
											? " " + equipments[template.equipmentId].name
											: " "}
									</div>
								</Tooltip>
							</CardFooter>
						</Card>
					</GridItem>
				);
			}
		}
		return components;
	};

	changeCurrentPage = page => {
		this.tableRef &&
			this.tableRef.current &&
			this.tableRef.current.setState({ page: page - 1 }, () =>
				this.tableRef.current.fireFetchData()
			);
	};

	render() {
		const {
			color,
			message,
			page,
			totalPages,
			totalDocs,
			sizePerPage,
			modeTable,
			wizard
		} = this.state;
		const {
			dispatch,
			classes,
			isFetchingList,
			type,
			label,
			title,
			user,
			isFetchingEquipments
		} = this.props;

		let mdPagination = user.role === ROLE_WEBMASTER ? 4 : 8;

		return isFetchingEquipments ? (
			<Activity />
		) : (
			<GridContainer>
				{!modeTable && (
					<Fragment>
						{this.renderTemplates()}
						{!wizard && (
							<GridContainer>
								<GridItem xs={12} sm={12} md={mdPagination}>
									<div className={classes.floatRight}>
										<Pagination
											currentPage={page}
											totalSize={totalDocs}
											sizePerPage={sizePerPage}
											totalPages={totalPages}
											theme="bootstrap"
											changeCurrentPage={this.changeCurrentPage}
										/>
									</div>
								</GridItem>
								<GridItem xs={12} sm={12} md={4}>
									<Button
										color={"primary"}
										className={classes.floatRight}
										onClick={() => this.addWizard(false)}
									>
										<Add />
										{` Ajout d'${label}`}
									</Button>
									<Button
										color={"rose"}
										className={classes.floatRight}
										onClick={() => this.setState({ modeTable: !modeTable })}
									>
										<ArtTrack />
										{" Tableau"}
									</Button>
								</GridItem>
								{user.role === ROLE_WEBMASTER && (
									<GridItem xs={12} sm={12} md={4}>
										<Button
											color={"blue"}
											className={classes.floatRight}
											onClick={() => {
												dispatch({
													type: SET_TEMPLATE_SYSTEM,
													payload: true
												});
												this.addWizard(false);
											}}
										>
											<Add />
											{` Ajout d'${label} système`}
										</Button>
									</GridItem>
								)}
							</GridContainer>
						)}
					</Fragment>
				)}
				<GridItem xs={12} className={modeTable ? "" : classes.displayNone}>
					<Card>
						<CardHeader icon>
							<CardIcon color="primary">
								{type === "Activity" ? (
									<RiHeartsFill />
								) : type === "Proposal" ? (
									<FaHandHoldingHeart />
								) : (
									<FaRegNewspaper />
								)}
							</CardIcon>
							<h4 className={classes.cardIconTitle}>{title}</h4>
						</CardHeader>
						<CardBody>
							<ReactTable
								ref={this.tableRef}
								data={this.getTemplates()}
								filterable
								columns={[
									{
										Header: "Date",
										accessor: "date",
										sortable: true,
										filterable: false,
										Cell: props => this.formatDate(props.value)
									},
									{
										Header: "Nom",
										accessor: "name",
										sortable: true,
										filterable: false
									},
									{
										Header: "Catégorie",
										accessor: "category",
										sortable: true,
										filterable: false
									},
									{
										Header: "Actions",
										accessor: "actions",
										sortable: false,
										filterable: false
									}
								]}
								defaultSorted={[
									{
										id: "date",
										desc: true
									}
								]}
								loading={isFetchingList}
								onFetchData={(state, instance) => {
									this.setState({
										page: state.page + 1,
										totalPages: state.pages,
										totalDocs: state.data.length,
										sizePerPage: state.pageSize,
										data: state.sortedData
									});
								}}
								defaultPageSize={6}
								pageSizeOptions={[3, 6, 9, 18, 36, 72]}
								showPaginationTop
								showPaginationBottom={false}
								previousText="Précédent"
								nextText="Suivant"
								rowsText="Lignes"
								noDataText={
									type === "Activity"
										? "Aucuns instants partage"
										: type === "Proposal"
										? "Aucuns besoins"
										: "Aucuns modèles"
								}
								ofText="sur"
								loadingText="Chargement..."
								className="-striped -highlight"
							/>
						</CardBody>
						<CardFooter product>
							<div className={classes.w100}>
								{user.role === ROLE_WEBMASTER && (
									<Button
										color="blue"
										className={classes.floatRight}
										onClick={() => {
											dispatch({
												type: SET_TEMPLATE_SYSTEM,
												payload: true
											});
											this.addWizard(false);
										}}
										disabled={this.state.wizard}
									>
										<Add />
										{` Ajout d'${label} système`}
									</Button>
								)}
								<Button
									color="primary"
									className={classes.floatRight}
									onClick={() => this.addWizard(false)}
									disabled={this.state.wizard}
								>
									<Add />
									{` Ajout d'${label}`}
								</Button>
								<Button
									color="rose"
									className={classes.floatRight}
									onClick={() => this.setState({ modeTable: !modeTable })}
									disabled={this.state.wizard}
								>
									<ArtTrack />
									{" Fiches"}
								</Button>
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				{this.state.wizard}
				<Snackbar
					place="br"
					color={color}
					icon={AddAlert}
					message={message ? message : ""}
					open={this.state.br}
					closeNotification={() => this.setState({ br: false })}
					close
				/>
			</GridContainer>
		);
	}
}

class TemplatesPage extends Templates {
	static defaultProps = {
		type: "Template",
		label: "un modèle",
		title: "Modèles"
	};
}

class ActivitiesPage extends Templates {
	static defaultProps = {
		type: "Activity",
		label: "un instant partage",
		title: "Instants partage"
	};
}

class ProposalsPage extends Templates {
	static defaultProps = {
		type: "Proposal",
		label: "un besoin",
		title: "Besoins"
	};
}

Templates.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		isFetchingList,
		isFetching,
		templates,
		_id,
		categoryId,
		newCategoryId,
		name,
		description,
		postDescription,
		gooddealPostDescription,
		gooddealDescription,
		files,
		lang,
		dateId,
		timeId,
		equipmentId,
		acceptNotif,
		snackInfoMsg,
		snackErrorMsg,
		newWizard,
		photoUri,
		picto,
		system,
		choices,
		usedByGooddeals
	} = state.templateReducer;
	const { pictos } = state.uploadReducer;
	const { community } = state.entitiesReducer;
	const { user } = state.profileReducer;
	const { equipments, isFetchingList: isFetchingEquipments } =
		state.equipmentReducer;
	return {
		isFetchingList,
		isFetching,
		templates,
		_id,
		categoryId,
		newCategoryId,
		name,
		description,
		postDescription,
		gooddealPostDescription,
		gooddealDescription,
		files,
		lang,
		dateId,
		timeId,
		equipmentId,
		acceptNotif,
		snackInfoMsg,
		snackErrorMsg,
		newWizard,
		photoUri,
		picto,
		user,
		system,
		choices,
		equipments,
		isFetchingEquipments,
		community,
		usedByGooddeals,
		pictos
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetEquipments,
			fetchGetTemplates,
			fetchAddTemplate,
			fetchGetTemplateDetails,
			fetchUpdateTemplate,
			fetchDeleteTemplate,
			fetchCloneTemplate,
			fetchGetPictosUri
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default {
	TemplatesPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(TemplatesPage)),
	ActivitiesPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(ActivitiesPage)),
	ProposalsPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(ProposalsPage)),
	TemplateWizard: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(TemplateWizard))
};
